const axios = require('axios');

let axRequestHeader = {
    headers: {
        "X-Requested-With":"XMLHttpRequest",
        "Cache-Control":"private, no-cache, no-store, must-revalidate",
        "Expires":"Tue, 01 Jan 1980 1:00:00 GMT"
    }
};

export let axClient= {
    get: function(url, callback, callbackError) {
        url = this.axTimestamp(url);
        axios.get(url, axRequestHeader)
            .then(function(response) {
                callback(response);
            })
            .catch(function (error) {
                if(callbackError != null)
                    callbackError(error);
            });
    },
    post: function (url, postData, callback, callbackError) {
        axios.post(url, postData, axRequestHeader)
            .then(function(response) {
                if (callback != null)
                    callback(response);
            })
            .catch(function (error) {
                console.log(error);
                if(callbackError != null)
                    callbackError(error);
            });
    },
    put: function (url, postData, callback, callbackError) {
        axios.put(url, postData, axRequestHeader)
            .then(function(response) {
                if (callback != null)
                    callback(response);
            })
            .catch(function (error) {
                if(callbackError != null)
                    callbackError(error);
            });
    },
    delete: function (url, callback, callbackError) {
        axios.delete(url, axRequestHeader)
            .then(function(response) {
                callback(response);
            })
            .catch(function (error) {
                if(callbackError != null)
                    callbackError(error);
            });
    },
    axTimestamp: function(url) {
        if (url.indexOf("?") > 0) {
            url += "&";
        } else {
            url += "?";
        }
        url += "_t="+ new Date().getTime();
        return url;
    },
    apiGet: function (url, callback, callbackError) {
        this.get("/api"+url, callback, callbackError)
    },
    apiPost: function (url, postData, callback, callbackError) {
        this.post("/api"+url, postData, callback, callbackError)
    },
    apiPut: function (url, postData, callback, callbackError) {
        this.put("/api"+url, postData, callback, callbackError)
    },
    apiDelete: function (url, callback, callbackError) {
        this.delete("/api"+url, callback, callbackError)
    }
};