import {createApp} from 'vue';
//
import { createWebHistory, createRouter } from "vue-router";
//
import ToastService from 'primevue/toastservice';
import PrimeVue from "primevue/config";
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import Tooltip from 'primevue/tooltip';
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import Listbox from "primevue/listbox";
//
import VueKinesis from "vue-kinesis";
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
//
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);
//
import { useBreakpoints } from "@vueuse/core";
import Card from "primevue/card";
import Timeline from "primevue/timeline";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Vue3SmoothScroll from "vue3-smooth-scroll";

let app;
export let rmApp = {
    createApp: (component) => {
        let rootComponent = {
            setup: () => {
                return {};
            },
            data() {
                let result = {};
                if (component?.data) {
                    result = component.data;
                }
                result.breakpoints = useBreakpoints({
                    phoneSmall: 576,
                    phoneUp: 576,
                    tabletPortrait: 768,
                    tabletLandscape: 992,
                    desktop: 1200,
                    bigDesktop: 1400,
                });
                result.MobileLg = result.breakpoints.smaller("tabletPortrait");
                result.Mobile = result.breakpoints.smaller("tabletLandscape");
                result.DesktopMD = result.breakpoints.smaller("desktop");
                result.Desktop = result.breakpoints.greater("desktop");
                result.TabletAndBigger = result.breakpoints.greater("tabletPortrait");
                result.showMenu = false;
                result.events = [];
                return result;
            }
        };
        //
        if (component) {
            if (component.methods) {
                rootComponent.methods = component.methods;
            } else {
                rootComponent.methods = {}
            }
            if (component.mounted) {
                rootComponent.mounted = component.mounted;
            }
        }
        rootComponent.methods.rmShowHome = function() {
            window.location = "/";
        }
        rootComponent.methods.rmShowMenu = function(event) {
            this.showMenu = !this.showMenu;
            this.$refs.menuDesktop.toggle(event);
        }
        //browser history
        rootComponent.methods.rmHistoryBack = function () {
            history.back();
        }
        rootComponent.methods.rmScrollToTop = function() {
            window.scroll({
                top: 0,
                behavior: "smooth",
            });
        }

        app = createApp(rootComponent);
        app.use(PrimeVue, { ripple: true });
        app.use(ToastService);
        app.use(VueKinesis);
        app.use(Vue3SmoothScroll);
        app.use(Vue3VideoPlayer);

        let routes = [];
        const router = createRouter({
            history: createWebHistory(),
            routes,
        });
        app.use(router);
        //
        app.directive('ripple', Ripple);
        app.directive('styleclass', StyleClass);
        app.directive('tooltip', Tooltip);
        //
        app.component('p-tabview', TabView);
        app.component('p-tabpanel', TabPanel);
        app.component('p-inputtext', InputText);
        app.component('p-overlaypanel', OverlayPanel);
        app.component('p-sidebar', Sidebar);
        app.component('p-button', Button);
        app.component('p-listbox', Listbox);
        app.component('p-card', Card);
        app.component('p-timeline', Timeline);
        app.component('p-dialog', Dialog);
        app.component('p-divider', Divider);
        //
        app.component('Swiper', Swiper);
        app.component('SwiperCore', SwiperCore);
        app.component('SwiperSlide', SwiperSlide);
        app.component('Pagination', Pagination);
        app.component('Navigation', Navigation);

        app.mount('#app');

        if (!location.hash) {
            window.addEventListener("scroll", function () {
                let el = document.getElementById("vgrm__scroll-to-top");
                if (el) {
                    if (window.scrollY > 600) {
                        el.classList.add("scroll-to-top-active");
                    } else {
                        el.classList.remove("scroll-to-top-active");
                    }
                }
            })
        }
    }
};