import {rmApp} from "../../clients/app";
import {axClient} from "@/clients/axios";

rmApp.createApp({
    data: {
        productFinder: {
            selectedCategory: null,
            selectedMediaType: null,
            categories: [],
            mediaTypes: [],
            disableOption2: true,
            disableSubmit: true
        }
    },
    methods: {
        initProductFinder: function() {
            let vm = this;
            axClient.apiGet("/productfinder", function(resp) {
                let data = resp.data;
                vm.productFinder.categories.splice(0, vm.productFinder.categories.length);
                data?.forEach((item) => {
                    vm.productFinder.categories.push(item);
                });
            })
        },
        pFinderOption1Toggle: function(event) {
            this.$refs.pFinderOption1.toggle(event);
            let selectedCategory = this.productFinder.selectedCategory;
            if (selectedCategory && event.value) {
                this.productFinder.mediaTypes.splice(0, this.productFinder.mediaTypes.length)
                selectedCategory.mediaTypes.forEach((item) => {
                    this.productFinder.mediaTypes.push(item);
                });
                this.productFinder.disableOption2 = this.productFinder.mediaTypes.length < 1;
                this.productFinder.selectedMediaType = null;
            }
        },
        pFinderOption2Toggle: function(event) {
            this.$refs.pFinderOption2.toggle(event);
        },
        openProductFinder: function(uri) {
            console.log(uri)
            uri += "/product-finder/?category=" +this.productFinder.selectedCategory.id;
            if (this.productFinder.selectedMediaType != null) {
                uri += "&media_type=" +this.productFinder.selectedMediaType.key;
                //console.log(uri);
            }
            window.open(uri)
        }
    },
    mounted: function() {
        this.initProductFinder()
        //console.log(this.$refs);
    }
});